import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { AppRoutingModule } from "./app-routing.module";
import { MatInputModule } from "@angular/material/input";
import {MatPaginatorModule} from '@angular/material/paginator';
import { AppComponent } from "./app.component";
import { MsalModule, MsalService, MsalGuard, MSAL_INTERCEPTOR_CONFIG, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent, MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import { LogLevel, Configuration, BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { Services } from "./shared/services";
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from "./loader/loader.service";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from "./shared/constants.all";
import { ConfigService, CONFIG as environment } from "./shared/configService";
import { MatTableModule } from '@angular/material/table';
import { MatSortModule} from '@angular/material/sort';
import { Dashboard } from "./Dashboard/Dashboard.Component";
import { DownloadArchive } from './DownloadArchive/DownloadArchive.Component';
import { DecompressArchive } from './DecompressArchive/DecompressArchive.Component';
import { NextflowVirReport } from './NextflowVirReport/NextflowVirReport.Component';
import { NextflowVirReportPartial } from './NextflowVirReportPartial/NextflowVirReportPartial.Component';
import { GalaxyVirReport } from "./GalaxyVirReport/GalaxyVirReport.Component";
import { GalaxyVirReportPartial } from './GalaxyVirReportPartial/GalaxyVirReportPartial.Component';
import { GalaxyWorkflowMultipleInput } from "./GalaxyWorkflowMultipleInput/GalaxyWorkflowMultipleInput.Component";
import { GalaxyWorkflowStatistics } from "./GalaxyWorkflowStatistics/GalaxyWorkflowStatistics.Component";
import { RunningJobs } from "./RunningJobs/RunningJobs.Component";
import { AzureBlobDownload } from './AzureBlobDownload/AzureBlobDownload.Component';
import { UpdateDatabase } from './UpdateDatabase/UpdateDatabase.Component';
import { FailedJobs } from "./FailedJobs/FailedJobs.Component";
import { AzureDownloadSamples } from "./AzureDownloadSamples/AzureDownloadSamples.Component";

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig();
};

window.fetch(Constants.FolderPath_AppSettings)
  .then(res => res.json())
  .then((resp) => {
    let msal = resp;
    Constants.initialize(msal.ClientId,
      msal.Authority,
      msal.BaseURL,
      msal.FunctionName);

    return resp;
  }).catch(e => {
    return Constants.Undefined;
  });

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {

  return new PublicClientApplication({
    auth: {
      clientId: Constants.ClientId,
      authority: Constants.AzureAuthority,
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });

}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set( Constants.BaseURL+'*', ['api://'+Constants.FunctionName+'/PEQ-HiTS.Access']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    // loginFailedRoute: '/login-failed'
  };
}
//#endregion

@NgModule({ declarations: [
        AppComponent,
        LoaderComponent,
        Dashboard,
        DownloadArchive,
        DecompressArchive,
        NextflowVirReport,
        NextflowVirReportPartial,
        GalaxyVirReport,
        GalaxyVirReportPartial,
        GalaxyWorkflowMultipleInput,
        GalaxyWorkflowStatistics,
        RunningJobs,
        AzureBlobDownload,
        UpdateDatabase,
        FailedJobs,
        AzureDownloadSamples
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserAnimationsModule,
        AppRoutingModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatOptionModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MsalModule,
        NgbModule,
        MatSortModule,
        MatPaginatorModule,
        MatTableModule], providers: [
        Services,
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            deps: [ConfigService],
            multi: true
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
