<mat-card>
    <div *ngIf="errorMessageActive" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div *ngIf="successMessageActive" class="alert alert-success">
        {{ successMessage }}
    </div>

    <form novalidate [formGroup]="form" autocomplete="off" (ngSubmit)="run(form.value)"
        fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-card-header>Nextflow VirReport</mat-card-header>
        <mat-card-content>
            <mat-form-field>
                <mat-label>Batch Id</mat-label>
                <mat-select id="batch_id" formControlName="batch_id" [(value)] ="selectedBatch">          
                    @for (batch of batchList; track batch) {
                      <mat-option [value]="batch">{{batch}}</mat-option>
                    }
                  </mat-select>
                <mat-error *ngIf="hasError('batch_id', 'required')">Batch id is required</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Process</mat-label>
                <mat-select id="process" formControlName="process">
                    <mat-option value="VIRREPORT-NCBI">NCBI</mat-option>
                    <mat-option value="VIRREPORT-PVIRDB">PVirDB</mat-option>
                    <mat-option value="VIRREPORT-PVIRDB-NCBI">PVirDB and NCBI</mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('process', 'required')">Process is required</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>eMail</mat-label>
                <input matInput #input_file_name placeholder="eMail" id="email" formControlName="email" />
                <mat-error *ngIf="hasError('email', 'pattern')">Invalid eMail</mat-error>
            </mat-form-field>
        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button [style.background]="'#748b31'"
                matTooltip="Click here to downlaod details for batch id {{selectedBatch}}" type="submit"
                [disabled]="!form.valid || isDisabled()">
                Run
            </button>
            <span></span>
            <button type="button" mat-raised-button color="warn" (click)="cancel()" matTooltip="Click here to Cancel"
                [disabled]="isDisabled()">
                Cancel
            </button>
        </mat-card-actions>
    </form>
</mat-card>