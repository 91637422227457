<mat-card>
  <div *ngIf="errorMessageActive" class="alert alert-danger">
    {{ errorMessage }}
  </div>
  <div *ngIf="successMessageActive" class="alert alert-success">
    {{ successMessage }}
  </div>
  <mat-card-header>Update PVirDB and NCBI Data</mat-card-header>
  <mat-card-content>
    Warning this is a long running process, an update can take 2 days. Before running this please check with your team
    that no jobs are running and there is no plan to run jobs during the update process.
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button [style.background]="'#748b31'" matTooltip="Click here to run the update" (click)="run()">
      Run
    </button>
  </mat-card-actions>
</mat-card>