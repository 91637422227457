import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigModel } from "../models/ConfigModel";
import { Observable } from 'rxjs';

import { Constants } from "./constants.all";


export let CONFIG: ConfigModel;

@Injectable({
  providedIn: "root"
})
export class ConfigService {
  config: ConfigModel;

  constructor(private http: HttpClient) { }

  loadConfig() {
    return this.http
      .get<ConfigModel>(Constants.AppSettingsLocationNotRelative)
      .toPromise()
      .then(fetchedConfig => {
        this.config = fetchedConfig;
        CONFIG = this.config;
      });
  }

  public load() {
    return new Promise((resolve, reject) => {
      this.http.get(Constants.AppSettingsLocationRelative).subscribe((envResponse: any) => {
        let t = new ConfigModel();
        CONFIG = Object.assign(t, envResponse);
        resolve(Constants.TRUE);
      });

    });
  }

  getConfig() {
    return this.config;
  }

}