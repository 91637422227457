import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { StaticFunctions } from './shared/static.functions';
import { Router } from "@angular/router";
import { Constants } from './shared/constants.all';
import { Observable, Subject } from 'rxjs';
import { LoaderService } from "./loader/loader.service";
import { ConfigService } from "./shared/configService";
import { OurMsalAuthService } from './shared/ourmsalauthservice';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay = false;
  isLoadingBool: boolean = false;
  isLoading: Subject<boolean> = this._loaderService.isLoading;
  loggedIn: boolean = Constants.FALSE;

  public username: string = Constants.String_Undefined;;
  public displayUsername: boolean = Constants.FALSE;
  private readonly _destroying$ = new Subject<void>();
  public environment = environment;
  constructor(
    public _router: Router, private _loaderService: LoaderService, private _config: ConfigService,
    private _ourMsalAuthService: OurMsalAuthService) {

    this._loaderService.show();
    this._config.loadConfig();
    this._loaderService.isLoading.subscribe((value) => {
      this.isLoadingBool = value;
    });
    this.loggedIn = this._ourMsalAuthService.Fetch_LoggedIn();
    this.displayUsername = this._ourMsalAuthService.Fetch_Display_User_Name();
    this.username = this._ourMsalAuthService.Fetch_User_Name();

    this._loaderService.hide();
  }

  ngOnInit(): void {
   this._loaderService.show();
    this._ourMsalAuthService.customObservable.subscribe(() => {
      this.setUserName();
    });
    this.isIframe = window !== window.parent && !window.opener;

    this._ourMsalAuthService.SetupMSAL();
    this.setUserName();
    this._loaderService.hide();
  }

  private setUserName(): void {
    this.username = this._ourMsalAuthService.Fetch_User_Name();
    if (this.username != null && this.username.length > 0) {
      this.loggedIn = Constants.TRUE;
      this.displayUsername = Constants.TRUE;
    }

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  title = 'peqhits';

  public onClick_DownloadArchive(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.DownloadArchiveUrl);
  }
  public onClick_DecompressArchive(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.DecompressArchiveUrl);
  }
  public onClick_NextflowVirReport(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.NextflowVirReportUrl);
  }
  public onClick_NextflowVirReportPartial(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.NextflowVirReportPartialUrl);
  }
 
  public onClick_GalaxyVirReport(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.GalaxyVirReportUrl);
  }
  public onClick_GalaxyVirReportPartial(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.GalaxyVirReportPartialUrl);
  }
  public onClick_GalaxyWorkflowMultipleInput(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.GalaxyWorkflowMultipleInputUrl);
  }
  public onClick_GalaxyWorkflowStatistics(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.GalaxyWorkflowStatisticsUrl);
  }
  public onClick_RunningJobs(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.RunningJobsUrl);
  }
  public onClick_FailedJobs(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.FailedJobsUrl);
  }
  public onClick_AzureBlobDownload(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.AzureBlobDownloadUrl);
  }

  public onClick_UpdateDatabase(): void {
    StaticFunctions.NavigateToPage(this._router, Constants.UpdateDatabaseUrl);
  }


  public loginnow() {
    this._ourMsalAuthService.login();

  }

  logout() {
    this._ourMsalAuthService.logout();
  }

}



