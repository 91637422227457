export class ConfigModel {
  Authority: string;
  ClientId: string;
  BaseURL: string;
  APIDownloadArchive: string;
  APIDecompressArchive: string;
  APINextflowVirReport: string;
  APINextflowVirReportPartial: string;
  APIGalaxyVirReport: string;
  APIGalaxyVirReportPartial: string;
  APIGalaxyWorkflowMultipleInput: string;
  APIGalaxyWorkflowStatistics: string;
  APIGetAllRunningBatchJobs: string;
  APIGetAllFailedBatchJobs: string;
  APIDownloadBatchData: string;
  APIDownloadBatchDataFile: string;
  APIUpdateDatabase: string;
  APIDeleteBatchTask:string;
  APIReactivateBatchTask:string;
  APITerminateBatchTask:string;
  APIGetBatches:string;
  APIGetBatchFiles:string;
  APIKEY: string;
  FunctionName: string;
}

