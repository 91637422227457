<div class="DivHeader {{environment.env}}" role="banner">
  <table>
    <tr>
      <td style="width: 20%;"><a class="logo" title="Department of Agriculture, Fisheries and Forestry" href="/"></a>
      </td>
      <td style="width: 15%;"> </td>      
      <td><span class="application-name">
        {{environment.env}} Post Entry Quarantine - High Throughput Sequencing
        </span></td>
    </tr>
  </table>
</div>

<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div>
      <button mat-flat-button color="black" [matMenuTriggerFor]="options">
        Processes
      </button>
      <button mat-flat-button color="black" [matMenuTriggerFor]="reports">
        Task manager
      </button>
      <button mat-flat-button color="black" (click)="onClick_AzureBlobDownload()">
        Download outputs
      </button>
    </div>
    <span class="flexExpand"></span>
    <div *ngIf="!loggedIn">
      <button mat-flat-button color="black" (click)="loginnow()">
        Login
      </button>

    </div>
    <div *ngIf="loggedIn" class="text"> {{username}}
      <button mat-flat-button color="black" (click)="logout()">
        Log out
      </button>
    </div>
  </mat-toolbar-row>
  <mat-menu #options="matMenu">
    <button mat-menu-item (click)="onClick_DownloadArchive()">Download Archive</button>
    <button mat-menu-item (click)="onClick_DecompressArchive()">Decompress Archive</button>
    <button mat-menu-item [matMenuTriggerFor]="nextflow">Nextflow</button>
    <button mat-menu-item [matMenuTriggerFor]="galaxy">Galaxy Australia</button>
    <button mat-menu-item [matMenuTriggerFor]="admin">Admin</button>
    
  </mat-menu>
  <mat-menu #nextflow="matMenu">
    <button mat-menu-item (click)="onClick_NextflowVirReport()">VirReport</button>
    <button mat-menu-item (click)="onClick_NextflowVirReportPartial()">VirReport Partial</button>
  </mat-menu>
  <mat-menu #galaxy="matMenu">
    <button mat-menu-item (click)="onClick_GalaxyVirReport()">VirReport</button>
    <button mat-menu-item (click)="onClick_GalaxyVirReportPartial()">VirReport Partial</button>
    <button mat-menu-item [matMenuTriggerFor]="workflows">Workflows</button>
  </mat-menu>
  <mat-menu #workflows="matMenu">
    <button mat-menu-item (click)="onClick_GalaxyWorkflowMultipleInput()">Multiple Input</button>
    <button mat-menu-item (click)="onClick_GalaxyWorkflowStatistics()">Statistics</button>
  </mat-menu>

  <mat-menu #reports="matMenu">
    <button mat-menu-item (click)="onClick_RunningJobs()">Running Jobs</button>
    <button mat-menu-item (click)="onClick_FailedJobs()">Failed Jobs</button>
  </mat-menu>

  <mat-menu #admin="matMenu">
    <button mat-menu-item (click)="onClick_UpdateDatabase()">Update PVirDB and NCBI</button>
  </mat-menu>

</mat-toolbar>


<div class="containerMain">
  <router-outlet></router-outlet>
  <app-loader></app-loader>
</div>
<div class="container">
  <br>
  <h3>Contacts</h3><br>
  <p style="font-size:12px">
    <b>Candace Elliot</b><br>
    Ag Assistant Director,<br>   
    OSS - Post Entry Quarantine,<br>
    Biosecurity Plant and Science Services Division<br>
    Department of Agriculture, Fisheries and Forestry<br>
  <p style="font-size:12px">
    <b>Dr. Ruvini Lelwala</b><br>
    Bioinformatician<br>
    OSS - Post Entry Quarantine,<br>
    Biosecurity Plant and Science Services Division<br>
    Department of Agriculture, Fisheries and Forestry
  </p>
</div>

