import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { Constants } from '../shared/constants.all';
import { FormBuilder } from "@angular/forms";
import { LoaderService } from "../loader/loader.service";
import { Services } from "../shared/services";
import { ConfigService } from "../shared/configService";

@Component({
    selector: "App-UpdateDatabase",
    templateUrl: "UpdateDatabase.Component.html",
    styleUrls: ["UpdateDatabase.Component.css"],
    

})
export class UpdateDatabase implements OnInit {
    apiURL: string;
    errorMessageActive: boolean = Constants.FALSE;
    successMessageActive: boolean = Constants.FALSE;

    errorMessage?: string = Constants.Undefined;
    successMessage?: string = Constants.Undefined;

    constructor(private _router: Router, fb: FormBuilder, private _Service: Services, private _loader: LoaderService, private _appConfigService: ConfigService,
        private _changeDetector: ChangeDetectorRef) {
    }

    async ngOnInit() {        
        this.apiURL = this._appConfigService.getConfig().BaseURL +this._appConfigService.getConfig().APIUpdateDatabase;
    }
    public run() {
        this._loader.show();
        this._Service.callAPI("", this.apiURL)
            .subscribe(
                {
                    next: response => {
                        if (response !== undefined) {
                            this.successMessage = "Success - " + response;
                            this.successMessageActive = Constants.TRUE;
                            this.errorMessageActive = Constants.FALSE;
                            this._loader.hide();
                        }
                        else {
                            this.errorMessage = "Error in caling API";
                            this.errorMessageActive = Constants.TRUE;
                            this.successMessageActive = Constants.FALSE;
                            this._loader.hide();
                        }

                    },
                    error: err => {
                        this.errorMessage = err();
                        this.errorMessageActive = Constants.TRUE;
                        this.successMessageActive = Constants.FALSE;
                        this._loader.hide();
                    }
                }
            )
        console.log(this.apiURL)
        this._loader.hide()
    }


    ngOnDestroy(): void {

    }
}