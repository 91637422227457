import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, tap, } from "rxjs/operators";
import { ConfigService, CONFIG } from "./configService";

@Injectable()
export class Services {

  constructor(private http: HttpClient, private router: Router, private _appConfig: ConfigService
  ) {
    const undefinedConfig = this._appConfig.getConfig();
  }

  public callAPI(payload: any, url: string): any {
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key' : this._appConfig.getConfig().APIKEY,
        'Accept' : 'text/plain'
      }),
      responseType: 'text'
    }
    return this.http.post<any>(url, JSON.stringify(payload), HTTPOptions).pipe(
      catchError(this.handleError)
    );
  }

  public getRunningJobsAPI(url: string) {
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Ocp-Apim-Subscription-Key' : this._appConfig.getConfig().APIKEY
      })
    }
    return this.http.get<any[]>(url, HTTPOptions).pipe(
      catchError(this.handleError)
    );
  }

  public getFailedJobsAPI(url: string) {
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Ocp-Apim-Subscription-Key' : this._appConfig.getConfig().APIKEY
      })
    }
    return this.http.get<any[]>(url, HTTPOptions).pipe(
      catchError(this.handleError)
    );
  }

  public getBlobFromAPI(url: string) : Observable<any>{
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/octet-stream',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Ocp-Apim-Subscription-Key' : this._appConfig.getConfig().APIKEY
      }),
      responseType: 'blob'
    }
    return this.http.get(url, HTTPOptions);
  }


  getBatchSearch(url: string): Observable<any> {
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Ocp-Apim-Subscription-Key' : this._appConfig.getConfig().APIKEY
      })
    }
    return this.http.get<any>(url, HTTPOptions);
  }

  public getBatches(url: string) {
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Ocp-Apim-Subscription-Key' : this._appConfig.getConfig().APIKEY
      })
    }
    return this.http.get<any[]>(url, HTTPOptions).pipe(
      catchError(this.handleError)
    );
  }

  public DeleteJobAPI(url: string) {
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Ocp-Apim-Subscription-Key' : this._appConfig.getConfig().APIKEY
      })
    }
    return this.http.delete<any[]>(url, HTTPOptions).pipe(
      catchError(this.handleError)
    );
  }
  public ReactivateJobAPI(url: string) {
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept' : 'text/plain',
        'Ocp-Apim-Subscription-Key' : this._appConfig.getConfig().APIKEY
      }),
      responseType: 'text'
    }
    return this.http.post<any[]>(url, null, HTTPOptions).pipe(
      catchError(this.handleError)
    );
  }
  public TerminateJobAPI(url: string) {
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept' : 'text/plain',
        'Ocp-Apim-Subscription-Key' : this._appConfig.getConfig().APIKEY
      }),
      responseType: 'text'
    }
    return this.http.post<any[]>(url,null, HTTPOptions).pipe(
      catchError(this.handleError)
    );
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}`;
    }
    console.log('HTTP Error ' + errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

}