import { MatTableModule } from '@angular/material/table';
import { Component } from "@angular/core";
import { formatDate } from "@angular/common";
import { animate, state, style, transition, trigger } from '@angular/animations';

export interface NewsUpdates {
  Title: string;
  Date: string;
  description: string;
}

const ELEMENT_DATA: NewsUpdates[] = [
  {
    Date: '4-12-2023',
    Title: 'PEQ-HiTS now available',
    description: `PEQ-HiTS is now live in production!`,
  }
];

@Component({
  selector: "Dashboard",
  templateUrl: "Dashboard.Component.html",
  styleUrls: ["Dashboard.Component.css"],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class Dashboard {
  dataSource = ELEMENT_DATA;
  columnsToDisplay = ['Date', 'Title'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedNews: NewsUpdates | null;
}