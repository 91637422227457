import { Component, inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';


@Component({
  selector: 'app-display-dialog',
  standalone: true,
  imports: [],
  templateUrl: './display-dialog.component.html',
  styleUrl: './display-dialog.component.css'
})
export class DisplayDialogComponent {
  data = inject(MAT_DIALOG_DATA);
}
