<mat-card class="mat-elevation-z0">

    <mat-card-header>Search Download Samples</mat-card-header>
    <br>
    <form novalidate [formGroup]="Search" autocomplete="off" (ngSubmit)="run()">
      <mat-card-content>
        <mat-form-field>
          <mat-label>Batch ID</mat-label>        
          <mat-select id="batch_id" formControlName="batch_id" >          
            @for (batch of batchList; track batch) {
              <mat-option [value]="batch">{{batch}}</mat-option>
            }
          </mat-select>
        </mat-form-field> 
       
  
  
      </mat-card-content>
      <mat-card-actions>
        <button style="width:200px;color:white" mat-raised-button [style.background]="'#748b31'" type="submit" [disabled]="!Search.valid ">
          Search
        </button>
        <span></span>
        <button style="width:200px" type="button" mat-raised-button color="warn" (click)="reset()" matTooltip="Click here to Cancel">
          Clear
        </button>
      </mat-card-actions>
    </form>
    <br>
    <br>  
    <div class="mat-elevation-z8" [hidden]="!recordFound">
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
   
    <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
      <mat-header-cell *matHeaderCellDef>{{ column.field }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container  *ngIf ="i== 0"> {{ row.name }}</ng-container>
        <ng-container  *ngIf ="i== 1">
          <button type="button" mat-button color="primary" (click)="download(row.filePath,row.name)" matTooltip="{{ row.name }}">Download</button> 
          <button type="button" mat-button color="primary" (click)="view(row.filePath,row.name)" matTooltip="{{ row.name }}">View</button> 
        </ng-container>
      </mat-cell>       
    </ng-container>
    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  
  </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
  </div>
  
  
    <br>
  </mat-card>