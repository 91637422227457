import { AriaLivePoliteness } from "@angular/cdk/a11y";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Constants } from "./constants.all";

export class StaticFunctions {

    public static async ValidateUserOr401(_router: Router) // _loader: LoaderService
        : Promise<void> {
        //_loader.show();
        //#region Checks.

        let _valid: boolean = Constants.TRUE; // Invoke Service

        //#endregion

        if (!_valid) {
            // Redirect to Error Page with - 401 Message. (Using passed Router)

        }
    }

    public static NavigateToPage(_passedRouter: Router, _passedUrl: string) {
        _passedRouter.navigate([_passedUrl]);
    }

}