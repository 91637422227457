
<div id="main"class="container"> 
<h3>About</h3>
<p>
  The Operational Science and Surveillance (OSS) team at the Commonwealth’s Post Entry Quarantine (PEQ) facility, in Mickleham, Victoria, conduct plant disease diagnostics on imported, high-risk nursery stock. We use a range of diagnostic tools including traditional plant pathology techniques, molecular assays, and High Throughput Sequencing (HTS) to detect regulated plant pathogens which could harm primary industries and environment. 
</p><p>
  Post Entry Quarantine - High Throughput Sequencing (PEQ-HiTS) is an open, web-based platform for accessible, reproducible and transparent running of computational workflows required for the analysis and management of HTS data produced by OSS team.
</p>
<h3>News</h3>
<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
    <td mat-cell *matCellDef="let news"> {{news[column]}} </td>
  </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let news">
      <button mat-icon-button aria-label="expand row"
        (click)="(expandedNews = expandedNews === news ? null : news); $event.stopPropagation()">
        <mat-icon *ngIf="expandedNews !== news">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedNews === news">keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let news" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="example-news-detail" [@detailExpand]="news == expandedNews ? 'expanded' : 'collapsed'">
        <div class="example-news-description">
          {{news.description}}

        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let news; columns: columnsToDisplayWithExpand;" class="example-news-row"
    [class.example-expanded-row]="expandedNews === news" (click)="expandedNews = expandedNews === news ? null : news">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
</div>

