import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { Constants } from './shared/constants.all';
import { Dashboard } from './Dashboard/Dashboard.Component';
import { DownloadArchive } from './DownloadArchive/DownloadArchive.Component';
import { DecompressArchive } from './DecompressArchive/DecompressArchive.Component';
import { NextflowVirReport } from './NextflowVirReport/NextflowVirReport.Component';
import { GalaxyVirReport } from './GalaxyVirReport/GalaxyVirReport.Component';
import { GalaxyWorkflowMultipleInput } from './GalaxyWorkflowMultipleInput/GalaxyWorkflowMultipleInput.Component';
import { GalaxyWorkflowStatistics } from './GalaxyWorkflowStatistics/GalaxyWorkflowStatistics.Component';
import { NextflowVirReportPartial } from './NextflowVirReportPartial/NextflowVirReportPartial.Component';
import { GalaxyVirReportPartial } from './GalaxyVirReportPartial/GalaxyVirReportPartial.Component';
import { RunningJobs } from './RunningJobs/RunningJobs.Component';
import { AzureBlobDownload } from './AzureBlobDownload/AzureBlobDownload.Component';
import { MsalGuard } from "@azure/msal-angular";
import { UpdateDatabase } from './UpdateDatabase/UpdateDatabase.Component';
import { FailedJobs } from './FailedJobs/FailedJobs.Component';

const routes: Routes = [
  { path: Constants.DefaultRoute, component: Dashboard },
  { path: Constants.DashboardPage, component: Dashboard },
  { path: Constants.DownloadArchivePage, component: DownloadArchive, canActivate: [MsalGuard] },
  { path: Constants.DecompressArchivePage, component: DecompressArchive, canActivate: [MsalGuard] },
  { path: Constants.NextflowVirReportPage, component: NextflowVirReport, canActivate: [MsalGuard] },
  { path: Constants.NextflowVirReportPartialPage, component: NextflowVirReportPartial, canActivate: [MsalGuard] },
  { path: Constants.GalaxyVirReportPage, component: GalaxyVirReport, canActivate: [MsalGuard] },
  { path: Constants.GalaxyVirReportPartialPage, component: GalaxyVirReportPartial, canActivate: [MsalGuard] },
  { path: Constants.GalaxyWorkflowMultipleInputPage, component: GalaxyWorkflowMultipleInput, canActivate: [MsalGuard] },
  { path: Constants.GalaxyWorkflowStatisticsPage, component: GalaxyWorkflowStatistics, canActivate: [MsalGuard] },
  { path: Constants.RunningJobsPage, component: RunningJobs, canActivate: [MsalGuard] },
  { path: Constants.AzureBlobDownloadPage, component: AzureBlobDownload, canActivate: [MsalGuard] },
  { path: Constants.UpdateDatabasePage, component: UpdateDatabase, canActivate: [MsalGuard] },
  { path: Constants.FailedJobsPage, component: FailedJobs, canActivate: [MsalGuard]}
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
