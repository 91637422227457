import { InjectionToken } from "@angular/core";
import { StaticFunctions } from "./static.functions";
import appsettings from "../../assets/appsettings.json";


export const MSAL_INSTANCE = new InjectionToken<string>("MSAL_INSTANCE");

export const Defined = (_passedData: any): boolean =>
  _passedData !== Constants.Undefined;

export const DefinedAndNotNull = (_passedData: any): boolean =>
  _passedData !== Constants.Undefined && _passedData !== Constants.NULL;


export class Constants {

  public static Undefined = undefined;
  public static NegativeOne: number = -1;

  public static Zero: number = 0;
  public static One: number = 1;
  public static Two: number = 2;
  public static Three: number = 3;
  public static Fifty = 50;
  public static OneFifty = 150;

  public static AzureAuthority: string = Constants.Undefined;
  public static ClientId: string = Constants.Undefined;
  public static BaseURL: string = Constants.Undefined;
  public static FunctionName: string = Constants.Undefined;

  public static TRUE: boolean = true;
  public static FALSE: boolean = false;
  public static NULL = null;
  public static String_Undefined: string | undefined = undefined;
  public static String_COMMA: string = ",";
  public static Throw_ServerError = "Server error";

  public static Forward_Slash = "/";
  public static FolderPath_AppSettings = "assets/appsettings.json";

  public static AppSettingsLocationRelative: string =
    "../../assets/appsettings.json";
  public static AppSettingsLocationNotRelative: string =
    "assets/appsettings.json";

  static initialize(_clientId: string, _authorityId: string, _baseURL: string, _functionName: string) {
    Constants.ClientId = _clientId;
    Constants.AzureAuthority = _authorityId;
    Constants.BaseURL = _baseURL;
    Constants.FunctionName = _functionName
  }

  public static FloatLabel_AUTO = "auto";
  public static DefaultRoute = "";

  public static DashboardPage = "dashboard";
  public static DashboardUrl = "/dashboard";

  public static DownloadArchivePage = "downloadarchive";
  public static DownloadArchiveUrl = "/downloadarchive";

  public static DecompressArchivePage = "decompressarchive";
  public static DecompressArchiveUrl = "/decompressarchive";

  public static NextflowVirReportPage = "nextflowvirreport";
  public static NextflowVirReportUrl = "/nextflowvirreport";

  public static NextflowVirReportPartialPage = "nextflowvirreportpartial";
  public static NextflowVirReportPartialUrl = "/nextflowvirreportpartial";

  public static GalaxyVirReportPage = "galaxyvirreport";
  public static GalaxyVirReportUrl = "/galaxyvirreport";

  public static GalaxyVirReportPartialPage = "galaxyvirreportpartial";
  public static GalaxyVirReportPartialUrl = "/galaxyvirreportpartial";

  public static GalaxyWorkflowMultipleInputPage = "galaxyworkflowmultipleinput";
  public static GalaxyWorkflowMultipleInputUrl = "/galaxyworkflowmultipleinput";

  public static GalaxyWorkflowStatisticsPage = "galaxyworkflowstatistics";
  public static GalaxyWorkflowStatisticsUrl = "/galaxyworkflowstatistics";

  public static RunningJobsPage = "runningjobs";
  public static RunningJobsUrl = "/runningjobs";

  public static AzureBlobDownloadPage = "azureblobdownload";
  public static AzureBlobDownloadUrl = "/azureblobdownload";

  public static UpdateDatabasePage = "updatedatabase";
  public static UpdateDatabaseUrl = "/updatedatabase";

  public static FailedJobsPage = "failedjobs";
  public static FailedJobsUrl = "/failedjobs";
}