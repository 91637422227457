import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Constants } from "../src/app/shared/constants.all";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

window.fetch(Constants.FolderPath_AppSettings)
  .then(res => res.json())
  .then((resp) => {

    const msal = resp;

    window['appSettings'] = resp;

    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.log(err));
  });