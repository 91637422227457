
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
    MsalService,
    MsalBroadcastService,
    MSAL_GUARD_CONFIG,
    MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
    EventMessage,
    EventType,
    InteractionType,
    InteractionStatus,
    PopupRequest,
    RedirectRequest,
    AuthenticationResult,
    AuthError,
    AccountInfo,
} from "@azure/msal-browser";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

interface IdTokenClaims extends AuthenticationResult {
    idTokenClaims: {
        acr?: string;
    };
}

@Injectable({
    providedIn: "root",
})
export class OurMsalAuthService {
    private readonly _destroying$ = new Subject<void>();
    private _username: string | undefined | null = undefined;
    private _displayUsername: boolean = false;
    private _useremail: string | undefined = undefined;
    private _user: AccountInfo | null = this.FetchUser();
    private _loggedIn: boolean = false;
    private isIframe: boolean = false;



    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        public _router: Router,
    ) {
    }


    private customSubject = new Subject<any>();
    public customObservable = this.customSubject.asObservable();

    public CallSubscribedMethods() {
        this.customSubject.next(null);
    }

    public FetchUserName(): string | null | undefined {
        // if (this._user !== null && this._user !== undefined) {
        //     this.FetchUser();            
        // }
        this.FetchUser();
        return this._user !== null ? (this._user as AccountInfo).name : null;
    }

    private FetchUser(): AccountInfo | null {
        this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
        this._user = this.authService.instance.getActiveAccount();
        return this._user;
    }

    public setLoginDisplay() {
        this._loggedIn =
            this.authService.instance.getAllAccounts().length > 0;

        if (this._loggedIn) {
            this._username = this.FetchUserName();
            this.CallSubscribedMethods();
            this._router.navigate(["/"]);
        }
    }

    public CheckLoginDisplay(): boolean {
        return this.authService.instance.getAllAccounts().length > 0;
    }

    public login(userFlowRequest?: RedirectRequest | PopupRequest) {

        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {

            if (this.msalGuardConfig.authRequest) {
                this.authService
                    .loginPopup({
                        ...this.msalGuardConfig.authRequest,
                        ...userFlowRequest,
                    } as PopupRequest)

                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            } else {

                this.authService
                    .loginPopup(userFlowRequest)

                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });

            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({
                    ...this.msalGuardConfig.authRequest,
                    ...userFlowRequest,
                } as RedirectRequest);
            } else {
                this.authService.loginRedirect(userFlowRequest);
            }
        }
    }

    public logout() {
        this.authService.logout();
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    SetupMSAL(): void {
        this.isIframe = window !== window.parent && !window.opener;
        this.msalBroadcastService.inProgress$

            .pipe(
                filter(
                    (status: InteractionStatus) => status === InteractionStatus.None
                ),
                takeUntil(this._destroying$)
            )

            .subscribe(() => {
                this.setLoginDisplay();
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS ||
                        msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                ),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                let payload: IdTokenClaims = <AuthenticationResult>result.payload;
                return result;
            });

        this.msalBroadcastService.msalSubject$

            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_FAILURE ||
                        msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
                ),

                takeUntil(this._destroying$)
            )

            .subscribe((result: EventMessage) => {
                if (result.error instanceof AuthError) {
                    if (
                        result.error.message.includes(
                            "AAD"
                        )
                    ) {

                        this.login(undefined);
                    }
                }
            });

        this._username = this.FetchUserName();
        if (this._username !== null && this._username !== undefined) {
            this._loggedIn = true;
            this._displayUsername = true;
        }
        // this._useremail = this.FetchUserEmail();
    }

    public Fetch_User_Name(): string | null | undefined {
        return this._username;
    }

    public Fetch_Display_User_Name(): boolean {
        return this._displayUsername;
    }

    public Fetch_User_Email(): string | null | undefined {
        return this._useremail;
    }

    public Fetch_User_Account_Info(): AccountInfo | null | undefined {
        return this._user;
    }

    public Fetch_LoggedIn(): boolean {
        return this._loggedIn;
    }

}