<mat-card>
  <mat-card-header>Failed Workflows</mat-card-header>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="batchId">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Batch </mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.batchId }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="sampleId">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Sample </mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.sampleId }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="saccId">
        <mat-header-cell *matHeaderCellDef mat-sort-header> SACC </mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.saccId }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="taskId">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Task </mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.taskId }}</mat-cell>
      </ng-container>     
      <ng-container matColumnDef="Action"> 
        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="btn-delete">
          <button mat-stroked-button (click)="DeleteItem(row.id,row.jobId)">Delete</button>
        </div>
          <div class="btn-rerun">
          <button mat-stroked-button (click)="ReactivateItem(row.id,row.jobId)">Rerun</button>
        </div>
        </mat-cell>      
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </table>
    <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
</mat-card>