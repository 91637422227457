<mat-card>
    <div *ngIf="errorMessageActive" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div *ngIf="successMessageActive" class="alert alert-success">
        {{ successMessage }}
    </div>

    <form novalidate [formGroup]="form" autocomplete="off"
        (ngSubmit)="run(form.value)" fxLayout="column wrap" fxLayoutAlign="center center"
        fxLayoutGap="10px">
        <mat-card-header>Decompress Archive</mat-card-header>

        <mat-card-content>
            <mat-form-field>
                <mat-label>Batch Id</mat-label>
                <mat-select id="batch_id" formControlName="batch_id" [(value)] ="selectedBatch" (selectionChange)="selectedValue($event)">    
                    @for (batch of batchList; track batch) {
                      <mat-option [value]="batch">{{batch}}</mat-option>
                    }
                  </mat-select>
                <mat-error *ngIf="hasError('batch_id', 'required')">Batch Id is required</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>File Name</mat-label>
                <input matInput #input_file_name placeholder="File Name" id="file_name" formControlName="file_name" [ngModel]="fileName" [readonly]="true"
                    minlength="5" maxlength="50" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>eMail</mat-label>
                <input matInput #input_file_name placeholder="eMail" id="email" formControlName="email" />
                <mat-error *ngIf="hasError('email', 'pattern')">Invalid Email</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Galaxy Australia API Key</mat-label>
                <input matInput #input_user placeholder="API Key" id="apiKey" formControlName="apiKey" />
            </mat-form-field>
            <div>
                <span> <mat-checkbox formControlName="continue_workflow" labelPosition="before">Continue
                        Workflow</mat-checkbox>
                </span>
            </div>
        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button [style.background]="'#748b31'"
                matTooltip="Click here to details for batch id {{selectedBatch}}" type="submit"
                [disabled]="!form.valid || isDisabled()">
                Run
            </button>
            <span></span>
            <button type="button" mat-raised-button color="warn" (click)="cancel()" matTooltip="Click here to Cancel"
                [disabled]="isDisabled() ">
                Cancel
            </button>
        </mat-card-actions>
    </form>
</mat-card>