import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { Constants } from '../shared/constants.all';
import { GalaxyVirReportStructure } from "./GalaxyVirReportStructure";
import { GalaxyVirReportModel } from "../models/GalaxyVirReportModel";
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Services } from "../shared/services";
import { LoaderService } from "../loader/loader.service";
import { ConfigService } from "../shared/configService";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: "App-GalaxyVirReport",
    templateUrl: "GalaxyVirReport.Component.html",
    styleUrls: ["GalaxyVirReport.Component.css"]

})
export class GalaxyVirReport implements OnInit {

    form: FormGroup;
    errorMessageActive: boolean = Constants.FALSE;
    successMessageActive: boolean = Constants.FALSE;
    
    errorMessage?: string = Constants.Undefined;
    successMessage?: string = Constants.Undefined;
    isButtonDisabled?: boolean = Constants.TRUE;
    apiURL: string;
    batchList: any;

    constructor(private _router: Router, fb: FormBuilder, private _Service: Services, private _loader: LoaderService, private _appConfigService: ConfigService,
        private _changeDetector: ChangeDetectorRef) {

        this.form = fb.group({
            hideRequired: Constants.FALSE,
            floatLabel: Constants.FloatLabel_AUTO
        });

        this.form = new FormGroup(
            {
                batch_id: new FormControl({ value: '', disabled: Constants.FALSE },
                    [Validators.required]),
                api_key: new FormControl({ value: '', disabled: Constants.FALSE }),
                email: new FormControl({ value: '', disabled: Constants.FALSE },
                    [Validators.pattern('^[a-zA-Z0-9._% -]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]
                ),
                continue_workflow: new FormControl({ value: Constants.FALSE, disabled: Constants.FALSE })
            });
    }


    async ngOnInit() {
        this.apiURL = this._appConfigService.getConfig().BaseURL +this._appConfigService.getConfig().APIGalaxyVirReport;
        var batchUrl = this._appConfigService.getConfig().BaseURL + this._appConfigService.getConfig().APIGetBatches;
        this._Service.getBatches(batchUrl)
        .subscribe((res) => {
            this.batchList = res;
        });
    }
    

    ngAfterContentChecked(): void {
        this._changeDetector.detectChanges();
    }

    cancel() {
        this.isButtonDisabled = Constants.FALSE;
        this.form.reset({
            batch_id: '',
            api_key: '',
            email: '',
            continue_workflow: false
        });
        this.form.get('batch_id').clearValidators();
        this.form.get('batch_id').updateValueAndValidity();
        this.errorMessageActive = false;
        this.successMessageActive = false;
        this.errorMessage = "";
        this.successMessage = "";
    }

    isDisabled() {
        if (this.form.touched)
            this.isButtonDisabled = Constants.FALSE;
        return this.isButtonDisabled;
    }

    public run = (_passeRunGAWorkflowDetail: GalaxyVirReportStructure) => {
        if (this.form.valid && this.form.touched) {
            this.runViaApi(_passeRunGAWorkflowDetail);
        }
    }

    private runViaApi(_details: GalaxyVirReportStructure) {
        this._loader.show();
        var requestItem = new GalaxyVirReportModel();
        requestItem.batchId = _details.batch_id.toString();
        requestItem.continueWorkflow = _details.continue_workflow;
        requestItem.apiKey = _details.api_key;
        requestItem.email = _details.email;


        this._Service.callAPI(requestItem, (this.apiURL))
            .subscribe(
                {
                    next: response => {
                        this.isButtonDisabled = Constants.TRUE;
                        if (response !== undefined) {
                            this.successMessage = "Success - " + response;
                            this.successMessageActive = Constants.TRUE;
                            this.errorMessageActive = Constants.FALSE;
                            this._loader.hide();
                        }
                        else {
                            this.errorMessage = "Error in caling API";
                            this.errorMessageActive = Constants.TRUE;
                            this.successMessageActive = Constants.FALSE;
                            this._loader.hide();
                        }

                    },
                    error: err => {
                        this.errorMessage = err();
                        this.errorMessageActive = Constants.TRUE;
                        this.successMessageActive = Constants.FALSE;
                        this._loader.hide();
                    }
                }
            )

    }

    public hasError = (controlName: string, errorName: string) => {
        return this.form.touched && this.form.controls[controlName]?.hasError(errorName);
    }
}