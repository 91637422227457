import { Component, OnInit } from "@angular/core";
import { LoaderService } from "./loader.service";
import { Subject } from "rxjs";
import { Constants } from "../shared/constants.all";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  value = Constants.Fifty;
  mode = "indeterminate";
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  isLoadingBool: boolean = false;

  constructor(private loaderService: LoaderService) {  
  }

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((value) => {
      this.isLoadingBool = value;
    });
  }

}
