<mat-card>
    <div *ngIf="errorMessageActive" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div *ngIf="successMessageActive" class="alert alert-success">
        {{ successMessage }}
    </div>

    <form novalidate [formGroup]="form" autocomplete="off"
        (ngSubmit)="run(form.value)" fxLayout="column wrap" fxLayoutAlign="center center"
        fxLayoutGap="10px">
        <mat-card-header>Galaxy VirReport Partial</mat-card-header>

        <mat-card-content>
            <mat-form-field>
                <mat-label>Batch Id</mat-label>
                <mat-select id="batch_id" formControlName="batch_id" >          
                    @for (batch of batchList; track batch) {
                      <mat-option [value]="batch">{{batch}}</mat-option>
                    }
                  </mat-select>
                <mat-error *ngIf="hasError('batch_id', 'required')">Batch Id is required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Sample Names</mat-label>
                <textarea matInput #input_sample_names placeholder="Sample names (One per line)" formControlName="sample_names"
                    cdkTextareaAutosize [cdkAutosizeMinRows]="3" [cdkAutosizeMaxRows]=5></textarea>
                <mat-error *ngIf="hasError('sample_names', 'required')">Sample names required</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Galaxy Australia API Key</mat-label>
                <input matInput #input_batch_id placeholder="API key" id="api_key" formControlName="api_key" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>eMail</mat-label>
                <input matInput #input_file_name placeholder="eMail" id="email" formControlName="email" />
                <mat-error *ngIf="hasError('email', 'pattern')">Invalid Email</mat-error>
            </mat-form-field>

            <div>
                <span> <mat-checkbox formControlName="continue_workflow" labelPosition="before">Continue
                        Workflow</mat-checkbox> </span>
            </div>

        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button  [style.background]="'#748b31'" matTooltip="Click here  for batch id {{input_batch_id.value}}"
                type="submit" [disabled]="!form.valid || isDisabled()">
                Run
            </button>
            <span></span>
            <button type="button" mat-raised-button color="warn" (click)="cancel()" matTooltip="Click here to Cancel"
                [disabled]="isDisabled()">
                Cancel
            </button>
        </mat-card-actions>
    </form>
</mat-card>