import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LoaderService } from "../loader/loader.service";
import { Services } from "../shared/services";
import { ConfigService } from "../shared/configService";
import { Subscription, timer } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from "@angular/material/paginator";

export interface Jobs {
    batchId: string;
    sampleId: string;
    saccId: string;
    taskId: string;
    action:string;
}

@Component({
    selector: "App-FailedJobs",
    templateUrl: "FailedJobs.Component.html",
    styleUrls: ["FailedJobs.Component.css"],
    

})
export class FailedJobs implements OnInit {
    apiURL: string;
    displayedColumns: string[] = ['batchId','sampleId','saccId','taskId','Action'];
    public dataSource: MatTableDataSource<Jobs>;
    recordFound: boolean = false;
    private dataArray: any;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    //DATA: Jobs[];
    private updateSubscription: Subscription;
    constructor(private _router: Router, fb: FormBuilder, private _Service: Services, private _loader: LoaderService, private _appConfigService: ConfigService,
        private _changeDetector: ChangeDetectorRef) {
    }

    async ngOnInit() {
        this.GetRecords()
    }
  
    private GetRecords() {  
        this._loader.show();
        this.updateSubscription = timer(0, 60000).subscribe((val) => {
            this.apiURL = this._appConfigService.getConfig().BaseURL +this._appConfigService.getConfig().APIGetAllFailedBatchJobs;
            this._Service.getFailedJobsAPI(this.apiURL).subscribe((res: Jobs[]) => { 
                this.dataArray = res;
                this.dataSource = new MatTableDataSource<Jobs>(this.dataArray);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.recordFound = true;                 
                this._loader.hide();
            })     
        });
      }
      DeleteItem(id:string,jobId:string){
        this.apiURL = this._appConfigService.getConfig().BaseURL +this._appConfigService.getConfig().APIDeleteBatchTask+ "/" + jobId + "/" + id;
        this._Service.DeleteJobAPI(this.apiURL).subscribe(() => {
            this.GetRecords();
        } );     
      }
      ReactivateItem(id:string,jobId:string){
        this.apiURL = this._appConfigService.getConfig().BaseURL +this._appConfigService.getConfig().APIReactivateBatchTask+ "/" + jobId + "/" + id;
        this._Service.ReactivateJobAPI(this.apiURL).subscribe(() => {
            this.GetRecords();
        } );     
      }
    ngOnDestroy(): void {
        this.updateSubscription.unsubscribe();
        }
}