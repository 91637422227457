<mat-card>
    <div *ngIf="errorMessageActive" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div *ngIf="successMessageActive" class="alert alert-success">
        {{ successMessage }}
    </div>

    <form novalidate [formGroup]="form" autocomplete="off"
        (ngSubmit)="run(form.value)" fxLayout="column wrap" fxLayoutAlign="center center"
        fxLayoutGap="10px">
        <mat-card-header>Galaxy Workflow Multiple Input</mat-card-header>

        <mat-card-content>
            <mat-form-field>
                <mat-label>Batch ID</mat-label>
                <mat-select id="batch_id" formControlName="batch_id" [(value)] ="selectedBatch">          
          @for (batch of batchList; track batch) {
            <mat-option [value]="batch">{{batch}}</mat-option>
          }
        </mat-select>
        <mat-error *ngIf="hasError('batch_id', 'required')">Batch id is required</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Sample Name</mat-label>
                <input matInput #input_sample_name placeholder="Sample Name" id="sample_name"
                    formControlName="sample_name" />
                <mat-error *ngIf="hasError('sample_name', 'required')">Sample name is required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Sample Files</mat-label>
                <textarea matInput #input_sample_file placeholder="Sample Files" formControlName="sample_file_names"
                    cdkTextareaAutosize [cdkAutosizeMinRows]="3" [cdkAutosizeMaxRows]=5></textarea>
                <mat-error *ngIf="hasError('sample_file_names', 'required')">Sample Files required</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Galaxy Australia API Key</mat-label>
                <input matInput #input_api_key placeholder="API Key" id="api_key" formControlName="api_key" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>eMail</mat-label>
                <input matInput #input_email placeholder="eMail" id="email" formControlName="email" />
                <mat-error *ngIf="hasError('email', 'pattern')">Invalid eMail</mat-error>
            </mat-form-field>
            <div class="DivFont">
                <span> <mat-checkbox formControlName="continue_workflow" labelPosition="before">Continue
                        Workflow</mat-checkbox>
                </span>
            </div>
            <br>

        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button  [style.background]="'#748b31'" matTooltip="Click here  for batch id {{selectedBatch}}"
                type="submit" [disabled]="!form.valid || isDisabled()">
                Run 
            </button>
            <span></span>
            <button type="button" mat-raised-button color="warn" (click)="cancel()" matTooltip="Click here to Cancel"
                [disabled]="isDisabled()">
                Cancel
            </button>
        </mat-card-actions>
    </form>
</mat-card>